import { create } from 'zustand';
import { FAQAccordionPanel } from '../components/FAQ/FAQAccordion';
import { readDataFromLocalStorage, savedBookmarksKey, savedExpertsKey } from './localStorage';
import { Expert } from '../components/Expert';
import { computeDistance } from '../lib/utils';

export type FAQItem = FAQAccordionPanel & {
  tag: ThemeTag[];
  targetGroup: TargetGroup[];
  isFrequentlyAsked: boolean;
};

type StoreType = 'Map' | 'Faq';

export type ThemeTag = string;

export type TargetGroup = string;

type TagListItem = {
  id?: string;
  title: string;
  checked: boolean;
};

export interface ThemeListItem extends TagListItem {
  title: ThemeTag;
}

export type ThemeList = Array<ThemeListItem>;

export type LocationCords = {
  lat: number;
  lng: number;
};
export const defaultCenter: LocationCords = {
  lat: 48.13851235741911,
  lng: 11.564670707717493,
};

export interface TargetGroupListItem extends TagListItem {
  title: TargetGroup;
}
export type TargetGroupList = Array<TargetGroupListItem>;

export type AppLanguage = 'de' | 'en';

export interface StoreState<T extends FAQItem | Expert> {
  //Setter
  setItems: (items: Array<T>) => void;
  getItems: () => Array<T>;

  //App-Language (set in useFaqStore)
  language: AppLanguage;
  setLanguage: (language: AppLanguage) => void;

  searchQuery: string;
  setSearchQuery: (val: string) => void;

  savedQuestionsIDlist: Array<string>;
  showOnlysavedQuestions: boolean;

  savedExpertsIDlist: Array<string>;
  showOnlysavedExperts: boolean;
  showOnlyFrequentlyAskedQuestions: boolean;

  //set map center
  mapCenter: LocationCords;
  setMapCenter: (location: LocationCords) => void;
  getMapCenter: () => LocationCords;

  //set radius
  radius: number;
  setRadius: (radiusAmount: number) => void;
  getRadius: () => number;

  //saved Radius Select
  radiuSearchQuery: string;
  setRadiusSearchQuery: (val: string) => void;

  items: Array<T>;

  //themelist = category in map
  themeList: ThemeList;
  setthemeList: (list: ThemeList) => void;
  checkSingleTheme: (title: ThemeTag) => void;
  checkSingleTargetGroup: (id: string) => void;
  //we dont neeed in map
  targetGroupList: TargetGroupList;
  settargetGroupList: (list: TargetGroupList) => void;

  getFilteredthemeList: () => ThemeList;
  //not in map
  getFilteredtargetGroupList: () => TargetGroupList;
  filteredByFilterItems: Array<T>;
  filteredBySearchItems: Array<T>;
  filteredByRadiusSearch: Array<T>;
  getFilteredItemsResult: () => Array<T>;

  isRadiusSearchChanged: boolean;
  filterByRadius: (temp_filteredList: Expert[]) => void;
  //apply Filters
  applyFilters: () => void;
  filterBySearch: (val: string) => void;
  reset: () => void;
}

const useStore = <T extends FAQItem | Expert>(type: StoreType) =>
  create<StoreState<T>>((set, get) => ({
    //Setter
    setItems(items) {
      set({ items, filteredByFilterItems: items, filteredBySearchItems: items });
      get().applyFilters();
      get().filterBySearch(get().searchQuery);
    },
    getItems() {
      return get().items;
    },

    //App-Language (set in useFaqStore)
    language: 'de',
    setLanguage: (language: AppLanguage) => set(() => ({ language })),

    //SearchQuery
    searchQuery: '',
    setSearchQuery: (value: string) => set(() => ({ searchQuery: value })),

    //saved Questions/Items
    savedQuestionsIDlist: readDataFromLocalStorage(savedBookmarksKey),
    showOnlysavedQuestions: false,

    //saved Experts
    savedExpertsIDlist: readDataFromLocalStorage(savedExpertsKey),
    showOnlysavedExperts: false,

    showOnlyFrequentlyAskedQuestions: false,

    //saved Map Center
    mapCenter: defaultCenter,
    setMapCenter: (location: LocationCords) => {
      set({ mapCenter: location });
    },
    getMapCenter: () => {
      return get().mapCenter;
    },

    //saved Radius Select
    radius: -1,
    setRadius: (radiusAmount: number) => {
      set({ radius: radiusAmount });
    },
    getRadius: () => {
      return get().radius;
    },

    isRadiusSearchChanged: true,
    //saved Radius Select
    radiuSearchQuery: '',
    setRadiusSearchQuery: (value: string) => set(() => ({ radiuSearchQuery: value })),

    //unfiltered Lists (items, themes, targetgroup)
    items: [],
    themeList: [],
    setthemeList: (list: ThemeList) => {
      set({ themeList: list });
    },
    checkSingleTheme: (title: ThemeTag) => {
      set({
        themeList: get().themeList.map((themeItem: ThemeListItem) => {
          if (themeItem.title === title) {
            return {
              ...themeItem,
              checked: true,
            };
          } else {
            return {
              ...themeItem,
              checked: false,
            };
          }
        }),
      });
    },
    checkSingleTargetGroup: (id: string) => {
      set({
        targetGroupList: get().targetGroupList.map((item: TargetGroupListItem) => {
          if (item.id === id) {
            return {
              ...item,
              checked: true,
            };
          } else {
            return {
              ...item,
              checked: false,
            };
          }
        }),
      });
    },
    targetGroupList: [],
    settargetGroupList: (list: TargetGroupList) => {
      set({ targetGroupList: list });
    },

    //filtered Lists
    getFilteredthemeList: () => {
      return get().themeList.filter((theme: ThemeListItem) => theme.checked);
    },
    getFilteredtargetGroupList: () => {
      return get().targetGroupList.filter(
        (targetGroup: TargetGroupListItem) => targetGroup.checked,
      );
    },
    //filtered by all filtercategories (tag, targetgroup, saved)
    filteredByFilterItems: [],
    //filtered by searchquery
    filteredBySearchItems: [],

    getFilteredItemsResult: () => {
      return get().filteredByFilterItems.filter((filterItem) =>
        get().filteredBySearchItems.find(
          (searchItem) => filterItem.contentful_id === searchItem.contentful_id,
        ),
      );
    },

    //filter methods
    reset: () => {
      const themelist = get().themeList.map((themeItem) => {
        return {
          ...themeItem,
          checked: false,
        };
      });

      const targetGroupList = get().targetGroupList.map((targetGroupItem) => {
        return {
          ...targetGroupItem,
          checked: false,
        };
      });
      set({
        themeList: themelist,
        targetGroupList: targetGroupList,
        showOnlysavedQuestions: false,
        showOnlyFrequentlyAskedQuestions: false,
        showOnlysavedExperts: false,
        filteredByFilterItems: get().items,
        filteredBySearchItems: get().items,
        searchQuery: '',
        radius: -1,
        isRadiusSearchChanged: true,
      });
    },

    filteredByRadiusSearch: [],

    //filter method to filter by filtercategories
    applyFilters: (): void => {
      const filteredThemeList = get().getFilteredthemeList();
      const filteredTargetGroupList = get().getFilteredtargetGroupList();

      const temp_filteredListInitial: Array<T> = [...get().items];
      const savedQuestionsIDlist = get().savedQuestionsIDlist;

      if (type === 'Faq') {
        let temp_filteredList: FAQItem[];
        temp_filteredList = temp_filteredListInitial as Array<FAQItem>;

        if (get().showOnlysavedQuestions) {
          if (savedQuestionsIDlist.length === 0) {
            set({
              filteredByFilterItems: [],
            });
            return;
          }
          temp_filteredList = temp_filteredList.filter((item) => {
            return savedQuestionsIDlist.includes(item.id);
          });
        }

        if (get().showOnlyFrequentlyAskedQuestions) {
          temp_filteredList = temp_filteredList.filter((item) => {
            return item.isFrequentlyAsked;
          });
        }

        if (filteredThemeList.length === 0 && filteredTargetGroupList.length === 0) {
          set({
            filteredByFilterItems: temp_filteredList as Array<T>,
          });
          return;
        } else {
          if (filteredThemeList.length !== 0) {
            temp_filteredList = temp_filteredList.filter((item) => {
              return item.tag.some((tag) => {
                return filteredThemeList.some((theme) => theme.title === tag);
              });
            });
          }
          if (filteredTargetGroupList.length !== 0) {
            temp_filteredList = temp_filteredList.filter((item) => {
              return item.targetGroup.some((targetGroupTag) => {
                return filteredTargetGroupList.some(
                  (targetGroup) => targetGroup.title === targetGroupTag,
                );
              });
            });
          }
        }
        set({
          filteredByFilterItems: temp_filteredList as Array<T>,
        });
      } else {
        let temp_filteredList = temp_filteredListInitial as Array<Expert>;

        const savedExpertsIDlist = get().savedExpertsIDlist;

        if (get().showOnlysavedExperts) {
          temp_filteredList = temp_filteredList.filter((item) => {
            return savedExpertsIDlist.includes(item.contentful_id);
          });
        }

        if (filteredThemeList.length !== 0) {
          temp_filteredList = temp_filteredList.filter((item) => {
            return filteredThemeList.some(
              (theme: ThemeListItem) => theme.title === item.category?.title,
            );
          });
        }

        if (get().isRadiusSearchChanged) {
          set({ isRadiusSearchChanged: false });
          get().filterByRadius(temp_filteredList);
        } else {
          const filteredList = get().filteredByRadiusSearch.filter((filterItem) =>
            temp_filteredList.find(
              (searchItem) => filterItem.contentful_id === searchItem.contentful_id,
            ),
          );
          set({
            filteredByFilterItems: filteredList as Array<T>,
          });
        }
      }
    },

    filterByRadius: (temp_filteredList: Expert[]) => {
      const origin = get().getMapCenter();

      const temp_filteredByRadiusList: Array<Expert> = [];

      get().items.map((item) => {
        const expert = item as Expert;
        const destination = expert.location;

        if (destination !== undefined) {
          const distance = computeDistance(
            origin.lat,
            origin.lng,
            destination.lat,
            destination.lng,
            'K',
          );

          if (get().radius === -1 || distance * 1000 <= get().radius) {
            temp_filteredByRadiusList.push(item as Expert);
          }
        }
      });

      const filteredList = temp_filteredByRadiusList.filter((filterItem) =>
        temp_filteredList.find(
          (searchItem) => filterItem.contentful_id === searchItem.contentful_id,
        ),
      );

      set({
        filteredByFilterItems: filteredList as Array<T>,
        filteredByRadiusSearch: temp_filteredByRadiusList as Array<T>,
      });
    },
    //filter method to filter by searchquery
    filterBySearch: (val: string) => {
      if (type === 'Faq') {
        const tmpItems = get().items as Array<FAQItem>;
        set({
          filteredBySearchItems: tmpItems.filter(
            ({ summary, description, tag: tagList, targetGroup: targetGroupList }) =>
              summary.toLowerCase().includes(val.toLowerCase()) ||
              description.toLowerCase().includes(val.toLowerCase()) ||
              tagList.find((tag) => tag.toLowerCase().includes(val.toLowerCase())) ||
              targetGroupList.find((targetGroup) =>
                targetGroup.toLowerCase().includes(val.toLowerCase()),
              ),
          ) as Array<T>,
        });
      } else {
        const tmpItems = get().items as Array<Expert>;
        set({
          filteredBySearchItems: tmpItems.filter(
            ({ title, category, doctorsName, address }) =>
              title.toLowerCase().includes(val.toLowerCase()) ||
              category?.title.toLowerCase().includes(val.toLowerCase()) ||
              doctorsName?.toString().toLowerCase().includes(val.toLowerCase()) ||
              address.toString().toLowerCase().includes(val.toLowerCase()),
          ) as Array<T>,
        });
      }
    },
  }));

export const useMapStore = useStore<Expert>('Map');
export const useFaqStore = useStore<FAQItem>('Faq');
